import React from "react"

export default ({ name, className }) => {
  const href = `#${name}`

  return (
    <svg className={className} data-name={name}>
      <use xlinkHref={href} href={href} />
    </svg>
  )
}
