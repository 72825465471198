import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import { media } from "src/styles/breakpoints"
import variables from "src/styles/variables"
import StylesReset from "src/styles/reset"
import StylesBase from "src/styles/base"
import Footer from "src/components/Footer"
import Sprites from "src/assets/sprites/sprites.svg"
import "src/fragments"

class BaseLayout extends React.Component {
  render() {
    const pathname = this.props.location.pathname

    return (
      <ThemeProvider theme={variables}>
        <React.Fragment>
          <Helmet defer={false}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=no"
            />
          </Helmet>
          <StylesReset />
          <StylesBase />
          <main>{this.props.children}</main>
          <Footer />
          <IconSprites />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

export default BaseLayout

const IconSprites = styled(Sprites)`
  display: none;
`

export const PageWrapper = styled.div`
  overflow: hidden;

  ${media.tablet} {
    overflow: visible;
    clip-path: inset(0);
    clip: rect(0px, auto, auto, 0px);

    .-no-scroll & {
      clip-path: none;
      clip: auto;
    }
  }
`
