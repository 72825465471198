import React from "react"
import styled from "styled-components"

export default ({ type, message, className }) => {
  return <Message className={className + ` -${type}`}>{message}</Message>
}

const Message = styled.p`
  font-size: 14px;

  &.-error {
    font-weight: 600;
    color: red;
  }
`
