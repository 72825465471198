import { createGlobalStyle } from "styled-components"
// import { media } from "src/styles/breakpoints"

export default createGlobalStyle`
  body {
    background-color: ${p => p.theme.color.white};
    font-weight: ${p => p.theme.basefontWeight};
    font-size: ${p => p.theme.baseFontSize};
    line-height: ${p => p.theme.baseLineHeight};
    font-family: ${p => p.theme.font.primary};
    color: ${p => p.theme.color.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;

    &.-no-scroll {
      overflow: hidden;
    }
  }

  ::selection {
    background: red;
    color: #fff;
  }

  /*
    gatsby-image missing fade-in workaround for Safari
    https://github.com/gatsbyjs/gatsby/issues/20126#issuecomment-592594749
  */
  .gatsby-image-wrapper img {
    transition: opacity .4s ease 0s !important;
  }
`
