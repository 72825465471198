import React from "react"
import styled, { css } from "styled-components"

export default ({ label, size, className, onClick }) => {
  return (
    <Button size={size} className={className} onClick={onClick}>
      {label}
    </Button>
  )
}

const Button = styled.button`
  display: inline-block;
  height: 56px;
  padding: 0 42px;
  font-weight: 600;
  font-size: 17px;
  line-height: 52px;
  border: 2px solid #000;
  color: #000;

  ${p =>
    p.size === "small" &&
    css`
      height: 44px;
      padding: 0 30px 2px;
      line-height: 1;
    `}

  &:hover {
    background: #000;
    color: #fff;
  }
`
