import * as types from "src/store/actionTypes"
import { combineReducers } from "redux"

const searchState = (state = {}, action) => {
  switch (action.type) {
    case types.SET_SEARCH_STATE:
      const {
        payload: { searchState },
      } = action

      return searchState

    default:
      return state
  }
}

const searchResults = (state = null, action) => {
  switch (action.type) {
    case types.SET_SEARCH_RESULTS:
      const {
        payload: { searchResults },
      } = action

      return searchResults

    default:
      return state
  }
}

export default combineReducers({
  searchState,
  searchResults,
})
