const colors = {
  white: "#fff",
  black: "#000",
  grey: "#808080",
  concrete: "#f2f2f2",
  mercury: "#e5e5e5",
}

const variables = {
  // Colors
  color: {
    ...colors,

    primary: colors.black,
    text: colors.black,
    backgroundSearch: colors.concrete,
  },

  // Typography
  font: {
    primary: "neue-haas-unica, sans-serif",
    secondary: "Lexicon No2 wf, serif",
  },
  basefontWeight: 400,
  baseFontSize: "16px",
  baseLineHeight: 1.7,

  // Layout
  containerWidth: 1230,
  sidePadding: {
    phone: 20,
    tablet: 25,
    desktop: 25,
  },

  // Easing functions
  easing: {
    easeOutQuad: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    easeInOutQuad: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
    easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    easeInOutQuart: "cubic-bezier(0.77, 0, 0.175, 1)",
    easeOutExpo: "cubic-bezier(0.19, 1, 0.22, 1)",
    easeInOutExpo: "cubic-bezier(1, 0, 0, 1)",
  },
}

export default variables
