import wrapWithProvider from "./wrap-with-provider"
const WebFont = require("webfontloader")

export const onClientEntry = async () => {
  // Intersection observer polyfill
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const onInitialClientRender = async () => {
  WebFont.load({
    typekit: {
      id: "pyb4igd",
    },
    custom: {
      families: ["Lexicon No2 wf:n4,i4"],
      urls: ["/fonts/fonts.css"],
    },
  })
}

export const wrapRootElement = wrapWithProvider
