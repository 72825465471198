import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Container from "src/components/Container"
import Icon from "src/components/Icon"
import SocialMediaIcons from "src/components/SocialMediaIcons"
import NewsletterForm from "src/components/NewsletterForm"
import CookieNotice from "src/components/CookieNotice"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const { wordpressAirSettings, wordpressAcfOptions } = useStaticQuery(graphql`
    query FooterNavigationQuery {
      wordpressAcfOptions {
        options {
          site_cookie_notice {
            text
          }
        }
      }
      wordpressAirSettings {
        footer {
          title
          path
        }
      }
    }
  `)
  const {
    options: { site_cookie_notice },
  } = wordpressAcfOptions

  return (
    <Wrapper>
      <Container>
        <Columns>
          <Column>
            <Title>Follow us on social media</Title>
            <SocialMedia />
            <Link to="/">
              <Logo name="logo" />
            </Link>
          </Column>
          <Column>
            <Title>Sign up to the ArtReview newsletters</Title>
            <NewsletterForm extended={true} />
            <Navigation>
              {wordpressAirSettings.footer.map(({ title, path }, index) => (
                <NavigationItem key={index}>
                  <NavigationLink to={path}>{title}</NavigationLink>
                </NavigationItem>
              ))}
            </Navigation>
            <Copyright>© 2020 ArtReview</Copyright>
          </Column>
        </Columns>
      </Container>
      <CookieNotice text={site_cookie_notice.text} />
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding-bottom: 60px;
  padding-top: 25px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  pointer-events: none;

  ${Container} {
    pointer-events: all;
  }
`

const Columns = styled.div`
  ${media.tablet} {
    display: flex;
  }
`

const Column = styled.div`
  ${media.tablet} {
    &:first-child {
      width: 250px;
      margin-right: 10px;
    }

    &:last-child {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  ${media.desktop} {
    &:first-child {
      width: calc((100% - 70px) / 8 * 2 + 10px);
    }
  }
`

const Title = styled.h2`
  width: 100%;
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 17px;
`

const SocialMedia = styled(SocialMediaIcons)`
  margin-bottom: 62px;
  padding-top: 3px;

  ${media.tablet} {
    margin-bottom: 72px;
  }
`

const Logo = styled(Icon)`
  display: none;
  width: 145px;
  height: 24px;

  ${media.tablet} {
    display: inline-block;
  }
`

const Navigation = styled.ul`
  display: flex;
  flex-wrap: wrap;

  ${media.tablet} {
    display: block;
  }
`

const NavigationItem = styled.li`
  display: inline;
  margin-right: 20px;
`

const NavigationLink = styled(Link)`
  color: ${p => p.theme.color.grey};
  color: var(--subtext-color, ${p => p.theme.color.grey});
  transition: 0.1s;

  &:hover {
    color: #000;
    color: var(--text-color, #000);
  }
`

const Copyright = styled.p`
  display: none;
  color: ${p => p.theme.color.grey};
  color: var(--subtext-color, ${p => p.theme.color.grey});

  ${media.tablet} {
    display: block;
  }
`
