import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Button from "src/components/Button"
import wpHttpClient from "src/utils/wpHttpClient"
import { useStaticQuery, graphql } from "gatsby"
import Message from "src/components/Message"
import Icon from "src/components/Icon"
import { get } from "lodash"
import countryList from "src/utils/countryList"

export default ({ extended, className }) => {
  const { wordpressAirSettings } = useStaticQuery(graphql`
    query NewsletterQuery {
      wordpressAirSettings {
        newsletter {
          interests {
            label
            value
          }
        }
      }
    }
  `)

  const newsletterInterests = get(
    wordpressAirSettings,
    "newsletter.interests",
    []
  )
  const timeoutId = useRef()
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [country, setCountry] = useState("")

  const initialInterests = {}
  for (const interest of newsletterInterests) {
    initialInterests[interest.value] = false
  }
  const [interests, setInterests] = useState(initialInterests)
  const initialResponse = Object.freeze({
    type: null,
    message: null,
  })
  const [response, setResponse] = useState(initialResponse)

  const onChange = e => {
    const updatedInterests = Object.assign({}, interests, {
      [e.target.name]: e.target.checked,
    })
    setInterests(updatedInterests)
  }

  const onSubmit = async e => {
    e.preventDefault()

    setResponse({
      type: null,
      message: null,
    })

    try {
      const results = await wpHttpClient.post("air/v1/site/newsletter", {
        email,
        name,
        country,
        interests,
      })
      setResponse({
        type: "success",
        message: results.data,
      })
    } catch (e) {
      setResponse({
        type: "error",
        message: e.response.data.message,
      })
    }
  }

  useEffect(() => {
    if (!response.message) return

    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      setResponse(initialResponse)
    }, 4000)
  }, [response])

  return (
    <Wrapper
      className={
        className + (extended ? " -extended" : "") + (email ? " -filled" : "")
      }
      onSubmit={onSubmit}
    >
      {extended ? (
        <>
          <TextFields>
            <Input
              type="email"
              placeholder="Your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required={true}
            />
            <Input
              type="text"
              placeholder="Your name"
              value={name}
              onChange={e => setName(e.target.value)}
              required={true}
            />
            <CountryInputWrapper>
              <CountryInputValue>
                {country || "Country"} <DropdownIcon name="chevron-down" />
              </CountryInputValue>
              <CountryInput
                value={country}
                onChange={e => setCountry(e.target.value)}
                required={true}
              >
                <CountryInputOption value="">Country</CountryInputOption>
                {countryList.map((country, index) => (
                  <CountryInputOption key={index}>{country}</CountryInputOption>
                ))}
              </CountryInput>
            </CountryInputWrapper>
            <SubmitButton type="submit" label="Subscribe" />
          </TextFields>
          <Options>
            {newsletterInterests.map(({ label, value }, index) => (
              <Option key={index}>
                <OptionInput
                  onChange={onChange}
                  type="checkbox"
                  name={value}
                  checked={interests[value]}
                />
                <OptionControl />
                <OptionName>{label}</OptionName>
              </Option>
            ))}
          </Options>
          {response && response.message && <StyledMessage {...response} />}
        </>
      ) : (
        <>
          <Input
            type="email"
            placeholder="Your email…"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required={true}
          />
          {response && response.message && <StyledMessage {...response} />}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.form`
  width: 100%;
  margin-bottom: 66px;
  position: relative;
  color: #000;
  color: var(--text-color, #000);
`

const Input = styled.input`
  width: 100%;
  padding: 18px 0;
  border: 2px solid;
  border-width: 0 0 2px;
  border-radius: 0;
  font-weight: 600;
  font-size: 16px;
  color: inherit;

  &::placeholder {
    opacity: 0.5;
    font-weight: normal;
    color: inherit;
    transition: 0.1s;
  }

  &:focus {
    &::placeholder {
      opacity: 0.3;
    }
  }
`

const TextFields = styled.div`
  margin-bottom: 20px;

  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    > * {
      width: 50%;
    }

    ${Input} {
      max-height: 56px;
      padding: 16px 0;
      border-bottom-width: 0;
    }
  }

  ${media.desktop} {
    > :nth-child(1),
    > :nth-child(2) {
      width: 30%;
    }

    > :nth-child(3),
    > :nth-child(4) {
      width: 20%;
    }

    ${Input} {
      border-bottom-width: 2px;

      &:nth-child(2) {
        border-right-width: 0;
      }
    }
  }
`

const CountryInputWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  border: solid #000;
  border-width: 0 0 2px;

  ${media.tablet} {
    margin-bottom: 0;
  }
`

const CountryInputValue = styled.div`
  opacity: 0.9;
  overflow: hidden;
  padding: 17px 0;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${media.tablet} {
    padding-right: 24px;
    padding-bottom: 0;
  }
`

const DropdownIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  margin-left: 6px;
`

const CountryInput = styled.select`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const CountryInputOption = styled.option``

const SubmitButton = styled(Button)``

const Options = styled.div`
  ${media.tablet} {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 127px;
    left: 0;
    transition: 0.15s;
    transition-property: visibility, opacity;

    ${Wrapper}.-filled &,
    ${Input}:focus ~ & {
      visibility: visible;
      opacity: 1;
    }
  }

  ${media.desktop} {
    top: 75px;
  }
`

const Option = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 4px;
  user-select: none;

  ${media.tablet} {
    margin-bottom: 0;
  }
`

const OptionInput = styled.input`
  position: absolute;
  left: -9000px;
`

const OptionControl = styled.span`
  display: inline-block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
  margin-right: 10px;
  border: 2px solid;

  &::before {
    content: "";
    visibility: hidden;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    background: currentColor;
  }

  ${OptionInput}:checked ~ &::before {
    visibility: visible;
  }
`

const OptionName = styled.span`
  font-weight: 600;
`

const StyledMessage = styled(Message)`
  position: absolute;
  top: 80px;
  left: 0;

  ${Wrapper}.-extended & {
    position: static;
    margin-top: 70px;
  }
`
