import React, { useState } from "react"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"

export default ({ text }) => {
  const storageItemName = "cookie-notice"
  const [seen, setSeen] = useState(
    typeof localStorage !== "undefined" && localStorage.getItem(storageItemName)
  )

  function handleClose() {
    setSeen(true)
    localStorage.setItem(storageItemName, 1)
  }

  return !seen ? (
    <Wrapper>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
      <CloseButton onClick={handleClose}>OK</CloseButton>
    </Wrapper>
  ) : (
    <></>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  padding-right: 60px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: all;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const Text = styled.p`
  max-width: 762px;
  font-size: 12px;
  line-height: 1.43;
  color: #909090;

  a {
    color: ${p => p.theme.color.text};
    color: var(--text-color, ${p => p.theme.color.text});
  }

  ${media.tablet} {
    font-size: 14px;
  }
`

const CloseButton = styled.button`
  padding: 15px 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  color: ${p => p.theme.color.text};
  color: var(--text-color, ${p => p.theme.color.text});
`
