import axios from "axios"

class http {
  instance = null

  constructor(baseUrl, args = {}) {
    args = Object.assign(args, {
      baseURL: baseUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
    this.instance = axios.create(args)
  }

  get(url = "", data = {}) {
    // const args = this.parseArgs(arguments)
    return this.instance.get(url, data)
  }

  post(url = "", data = {}) {
    // const args = this.parseArgs(arguments)
    return this.instance.post(url, data)
  }

  //   parseArgs(args) {
  //     let url = args[0]
  //     let data = args[1]

  //     if (url instanceof Object) {
  //       data = url
  //       url = ""
  //     }

  //     return {
  //       url,
  //       data,
  //     }
  //   }
}

export default http
