export const breakpoints = {
  phone: 320,
  tablet: 768,
  desktop: 1024,
  desktopMedium: 1200,
}

const customMediaQuery = maxWidth => `@media (min-width: ${maxWidth}px)`

export const media = {
  phone: customMediaQuery(breakpoints.phone),
  tablet: customMediaQuery(breakpoints.tablet),
  desktop: customMediaQuery(breakpoints.desktop),
  desktopMedium: customMediaQuery(breakpoints.desktopMedium),
  touch: "@media (hover: none) and (pointer: coarse)",
  pointer: "@media (hover: hover) and (pointer: fine)",
  custom: customMediaQuery,
}
